import React from 'react'
import { Home } from './Home'
// import { Navbar } from '../navbar/Navbar'
import { TimeMnagement } from './TimeMnagement'
// import { AllCourses } from './AllCourses'
// import { BoardSyllabus } from './BoardSyllabus'
// import { CompetitiveSyllabus } from './CompetitiveSyllabus'
// import { LatestNews } from './LatestNews'
import { Review } from './Review'
import { WebTechnology } from './course/WebTechnology'
import { PythonTechnology } from './course/PythonTechnology'
import { JavaTechnology } from './course/JavaTechnology'
// import { UnaniAiapget } from './entrancetest/aiapget/UnaniAiapget'
// import { EntranceTest } from './entrancetest/EntranceTest'

export const MainContainer = () => {
  return (
    <div className='flex flex-col'>
        {/* <Navbar/> */}
        <Home/>
        <TimeMnagement/>
        <WebTechnology/>
        <PythonTechnology/>
        <JavaTechnology/>
        {/* <AllCourses/> */}
        {/* <UnaniAiapget/> */}
        {/* <BoardSyllabus/> */}
        {/* <EntranceTest/> */}
        {/* <LatestNews/> */}
        <Review/>
    </div>
  )
}
