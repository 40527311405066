import React from 'react'
import {Python,Celery,Django,Flask,Metaplotlib,Numpy,Pandas,Requests,Tensorflowandpytorch,programming} from '../Image'
import { Link } from 'react-router-dom'
export const PythonTechnology = () => {
    const data = [
        {name: "coding questions",img: programming,href: "/coding/codingquestions",},
        { name: "Python", img: Python,  href: "/py/python",},
        {name: "Django",img: Django,href: "/py/django",},
        {name: "Flask",img: Flask,href: "/py/flask",},
        {name: "Numpy",img: Numpy, href: "/py/numpy",},
        { name: "Pandas", img: Pandas,  href: "/py/pandas",},
        {name: "Metaplotlib",img: Metaplotlib,href: "/py/metaplotlib",},
        {name: "Request",img: Requests,href: "/py/request",},
        {name: "Celery",img: Celery, href: "/py/celery",},
        { name: "TensorFlow and pyTorch", img: Tensorflowandpytorch,  href: "/py/tensorflowpytorch",},
    ]
  return (
    <div>
        <div className='container mx-auto'>
        <p className='text-4xl font-serif font-bold mt-10 text-center'>Python Technology</p>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center mt-10'>
          {data?.map((course, index) => (
            <div key={index} className='bg-custom-gray p-5 rounded-md text-center hover:bg-bg-image group shadow-lg w-full'>
              <Link to={course?.href}> {/* Use Link component and set 'to' prop to href */}
                <img className='mx-auto w-1/2 transition-transform duration-300 transform group-hover:translate-y-4 group-hover:bg-transparent rounded-md' src={course?.img} alt='' />
                <p className='font-bold p-1 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125 truncate'>{course?.name}</p>
                {/* <p className='text-sm p-1 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p> */}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
