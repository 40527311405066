import React from 'react'
import { WebTechnology } from './course/WebTechnology'
import { PythonTechnology } from './course/PythonTechnology'
import { JavaTechnology } from './course/JavaTechnology'
export const Btech = () => {
  return (
    <div className='flex flex-col'>
         <WebTechnology/>
        <PythonTechnology/>
        <JavaTechnology/>
    </div>
  )
}
