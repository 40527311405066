import React, { useState, useEffect } from 'react';

export const ObjectiveQue = ({ data, onUpdateCounts }) => {
    console.log(data,"jhj")
    return (
        <div className='flex flex-col items-center p-4'>
            {data?.data?.map((item) => (
                <QuestionItem key={item?.id} item={item} onUpdateCounts={onUpdateCounts} />
            ))}
        </div>
    );
};

const QuestionItem = ({ item, onUpdateCounts }) => {
    const [explain, setExplain] = useState(false);
    const [selected, setSelected] = useState(null);
    const [answered, setAnswered] = useState(false);

    const { id, question, code, optionA, optionB, optionC, answer, optionD, explain: explanation } = item;

    useEffect(() => {
        const storedAnswered = JSON.parse(localStorage.getItem(`answered_${id}`));
        if (storedAnswered) {
            setSelected(storedAnswered.selected);
            setAnswered(true);
        }
    }, [id]);

    const handleClick = (optionId) => {
        if (answered) return;
        setSelected(optionId);
        setAnswered(true);

        const isCorrect = optionId === answer;
        const statusKey = isCorrect ? 'correctCount' : 'incorrectCount';
        const storedCount = JSON.parse(localStorage.getItem(statusKey)) || 0;
        const newCount = storedCount + 1;
        localStorage.setItem(statusKey, JSON.stringify(newCount));

        localStorage.setItem(`answered_${id}`, JSON.stringify({ selected: optionId, isCorrect }));

        // Update parent counts
        onUpdateCounts(isCorrect);
    };

    const getOptionClass = (optionId) => {
        if (!answered) return 'border-2 p-2 shadow-xl font-custom-font hover:bg-slate-300 cursor-pointer';
        if (optionId === answer) return 'border-2 p-2 shadow-xl font-custom-font bg-green-300';
        if (optionId === selected) return 'border-2 p-2 shadow-xl font-custom-font bg-red-300';
        return 'border-2 p-2 shadow-xl font-custom-font';
    };

    return (
        <div className='w-3/4 max-w-lg bg-white shadow-2xl p-6 rounded-lg mb-6'>
            <p className='text-xl font-bold mb-4'>{id}.{question ?? "Question not available"}</p>
            {code && (
                <div className="code-container mb-4">
                    <code>
                        <pre className='bg-gray-200 p-4 rounded whitespace-pre-wrap'>{code}</pre>
                    </code>
                </div>
            )}
            <div className='space-y-2'>
                <p className={getOptionClass("A")} onClick={() => handleClick("A")}>(A) {optionA ?? "Option A not available"}</p>
                <p className={getOptionClass("B")} onClick={() => handleClick("B")}>(B) {optionB ?? "Option B not available"}</p>
                <p className={getOptionClass("C")} onClick={() => handleClick("C")}>(C) {optionC ?? "Option C not available"}</p>
                <p className={getOptionClass("D")} onClick={() => handleClick("D")}>(D) {optionD ?? "Option D not available"}</p>
            </div>
            <p onClick={() => setExplain(!explain)} className='mt-4 text-blue-500 cursor-pointer'>Explanation</p>
            {explain && explanation && (
                <div className='mt-4 p-4 bg-gray-100 rounded'>
                    <p className='font-custom-font'>
                        {explanation}
                    </p>
                </div>
            )}
        </div>
    );
};