import React, { Suspense } from 'react'
import { Routes ,Route} from 'react-router'
import { CodingQuestion } from './CodingQuestion'

export const AppScreenCoding = () => {
  return (
    <Suspense>
        <Routes>
            <Route path="codingquestions" element={<CodingQuestion/>}/>
        </Routes>
    </Suspense>
  )
}
