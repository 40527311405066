import React from 'react'

export const Home = () => {
  return (
    
    <div>
    <div className='w-full h-screen flex justify-center mt-0'>
    <img className='w-full h-auto object-cover' src='https://cdn.pixabay.com/photo/2024/02/21/15/11/employees-8587901_1280.png' alt='Employees Office Team Work Tutor' />
</div>
<div className='bg-red-600'>
    {/* <h1 className='absolute top-40 left-14 text-black text-2xl md:text-2xl lg:text-2xl'>Online Education</h1>
    <h1 className='absolute top-52 text-black text-5xl md:text-2xl lg:text-5xl'>
        Find The Best Online Course & Learn
    </h1> */}

    {/* <div className='absolute top-80 md:left-[100px] w-full md:w-1/2 lg:w-1/3 bg-white border-solid border-2 rounded-xl p-3'>
        <input className='w-1/2 mr-7 p-2 focus:border-none border-none' type='text' placeholder='Enter keyword of Course' />
        <button className='w-1/3 ml-6 text-white bg-red-600 hover:bg-blue-700 p-2 rounded-lg font-serif'>
            Search Courses
        </button>
    </div> */}
</div>

</div>
  )
}
