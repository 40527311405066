import axios from "axios"

const baseURL = "https://skillvibes.in"

export const ObjectiveList = async(page = 1, pageSize = 10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/objective/`,{
            params: { page, page_size: pageSize }
        })
        // console.log(response,"respo")
        return response.data
    } catch (error) {
        throw new Error('fetching data failed')
    }
}