import React from 'react'
import { Link } from 'react-router-dom'
import {Javascript,Angular,Chart,Css,D3js,Express,Html,Jquery,Moment,Nest,Next,Node,Reactjs,Redux,Three,Typescript,Vue} from '../../Image'

export const UnaniSyllabus = () => {
    const data = [
        
        {name: "Arabic and Mantiq wa Falsifa",img: Javascript,href: "/unani/Arabic_and_Mantiq_wa_Falsifa",},
        {name: "Kulliyat Umoore Tabiya",img: Reactjs,href: "/unani/Kulliyat_Umoore_Tabiya",},
        {name: "Tashreehul Badan",img: Angular, href: "/unani/Tashreehul_Badan",},
        { name: "Munafeul Aaza", img: Typescript,  href: "/unani/Munafeul_Aaza",},
        {name: "Tareekh e Tib",img: Redux,href: "/unani/Tareekh_e_Tib",},
        {name: "Tahaffuzi wa Samaji Tib",img: Vue,href: "/unani/Tahaffuzi_wa_Samaji_Tib",},
        {name: "Ilmul Advia",img: Jquery, href: "/unani/Ilmul_Advia",},
        {name: "Mahiyatul Amraz", img: Node,  href: "/unani/Mahiyatul_Amraz",},
        {name: "Communication Skills",img: Express,href: "/unani/Communication_Skills",},
        {name: "Ilmul Saidla wa Murakkabat",img: Next,href: "/unani/Ilmul_Saidla_wa_Murakkabat",},
        {name: "Tibbe Qanooni wa Ilmul Samoom",img: Nest, href: "/unani/Tibbe_Qanooni_wa_Ilmul_Samoom",},
        { name: "Sareeriyat wa Usoole Ilaj", img: Moment,  href: "/unani/Sareeriyat_wa_Usoole_Ilaj",},
        {name: "Ilaj Bit Tadbeer",img: Reactjs,href: "/unani/Ilaj_Bit_Tadbeer",},
        {name: "Amraz e Atfal",img: D3js,href: "/unani/Amraz_e_Atfal",},
        {name: "Moalajat",img: Three, href: "/unani/Moalajat",},
        {name: "Amraze Niswan", img: Chart,  href: "/unani/Amraze_Niswan",},
        {name: "Ilmul Qabalat wa Naumaulood",img: Reactjs,href: "/unani/Ilmul_Qabalat_wa_Naumaulood",},
        {name: "Ilmul Jarahat",img: Html,href: "/unani/Ilmul_Jarahat",},
        {name: "Ain Uzn Anaf Halaq wa Asnan",img: Css, href: "/unani/Ain_Uzn_Anaf_Halaq_wa_Asnan",},
    ]
  return (
    <div>
     <div className='container mx-auto'>
        <p className='text-4xl font-serif font-bold mt-10 text-center'>Web Technology</p>
        <div className='flex flex-wrap justify-evenly items-center box-content'>
          {data?.map((course, index) => (
            <div key={index} className='bg-custom-gray p-5 rounded-md text-center w-[150px] m-2 hover:bg-bg-image group shadow-lg'>
              <Link to={course?.href}> {/* Use Link component and set 'to' prop to href */}
                <img className='mx-auto w-1/2 transition-transform duration-300 transform group-hover:translate-y-4 group-hover:bg-transparent rounded-md' src={course?.img} alt='' />
                <p className='font-bold p-1 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125 truncate'>{course?.name}</p>
                {/* <p className='text-sm p-1 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p> */}
              </Link>
            </div>
          ))}
        </div>
      </div>   
    </div>
  )
}
