import axios from "axios"

const baseURL = 'https://skillvibes.in'


export const CourseData = async ()=>{
    console.log("hi")
    try {
        const response = await axios.get(`${baseURL}/courses/course/`);
        return response.data;
    } catch (error) {
         throw new Error('Error fetching data');
    }
}
export const SubcourseData = async (id)=>{
    console.log(id,"id")
    try {
        const response = await axios.get(`${baseURL}/courses/subcourse/${id}/`);
        return response.data;
    } catch (error) {
         throw new Error('Error fetching data');
    }
}
export const SyllabusData = async (id)=>{
    console.log(id,"id")
    try {
        const response = await axios.get(`${baseURL}/courses/syllabus/`);
        return response.data;
    } catch (error) {
         throw new Error('Error fetching data');
    }
}