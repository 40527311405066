import axios from "axios"

const baseURL = 'https://skillvibes.in'


export const PhysicsAllClass = async()=>{
    try {
        const physicsAllClass = axios.get(`${baseURL}/school/physics`);
        return physicsAllClass
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const PhysicsChapterName = async (classId) =>{
    try {
        const chapter  = await axios.get(`${baseURL}/school/physics-chapter-list/${classId}/`);
        return chapter.data;
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const PhysicsNptes = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/school/physics-notes/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}


// biology


export const BiologyAllClass = async()=>{
    try {
        const biologyAllClass = axios.get(`${baseURL}/school/biology`);
        return biologyAllClass
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const BiologyChapterName = async (classId) =>{
    try {
        const chapter  = await axios.get(`${baseURL}/school/biology-chapter-list/${classId}/`);
        return chapter.data;
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const BiologyNotes = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/school/biology-notes/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}





export const ChemistryAllClass = async()=>{
    try {
        const chemistryAllClass = axios.get(`${baseURL}/school/chemistry`);
        return chemistryAllClass
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const ChemistryChapterName = async (classId) =>{
    try {
        const chapter  = await axios.get(`${baseURL}/school/chemistry-chapter-list/${classId}/`);
        return chapter.data;
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const ChemistryNotes = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/school/chemistry-notes/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}





export const MathAllClass = async()=>{
    try {
        const mathAllClass = axios.get(`${baseURL}/school/math`);
        return mathAllClass
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const MathChapterName = async (classId) =>{
    try {
        const chapter  = await axios.get(`${baseURL}/school/math-chapter-list/${classId}/`);
        return chapter.data;
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const MathNotes = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/school/math-notes/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}





