import React from 'react'
import { Home } from '../../Home'
import { UnaniSyllabus } from './UnaniSyllabus'

export const UnaniAiapget = () => {
  return (
    <div className='flex flex-col'>
        <Home/>
        <UnaniSyllabus/>
    </div>
  )
}
