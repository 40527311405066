import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import {ArabicFalsifa, KulliyatTabiya, TashreehulBadan, MunafuelAaza, TareekhTib, TahaffuziSamajiTib, IlmulAdvia, MahiyatulAmraz, CommunicationSkill, IlmulMurakkabat, TibbeSamoom, SareeriyatIlaj, IlajTadbeer, AmrazAtfal, Moalajat, AmrazeNiswan, IlmulNaumaulood, IlmulJarahat, AinUznAnafAsnan} from './index'

export const AppScreenUnani = () => {
  return (
    <div>
         <Suspense>
      <Routes>
        <Route path='/Arabic_and_Mantiq_wa_Falsifa' element={<ArabicFalsifa/>} />
        <Route path='/Kulliyat_Umoore_Tabiya' element={<KulliyatTabiya/>} />
        <Route path='/Tashreehul_Badan' element={<TashreehulBadan/>} />
        <Route path='/Munafeul_Aaza' element={<MunafuelAaza/>} />
        <Route path='/Tareekh_e_Tib' element={<TareekhTib/>} />
        <Route path='/Tahaffuzi_wa_Samaji_Tib' element={<TahaffuziSamajiTib/>} />
        <Route path='/Ilmul_Advia' element={<IlmulAdvia/>} />
        <Route path='/Mahiyatul_Amraz' element={<MahiyatulAmraz/>} />
        <Route path='/Communication_Skills' element={<CommunicationSkill/>} />
        <Route path='/Ilmul_Saidla_wa_Murakkabat' element={<IlmulMurakkabat/>} />
        <Route path='/Tibbe_Qanooni_wa_Ilmul_Samoom' element={<TibbeSamoom/>} />
        <Route path='/Sareeriyat_wa_Usoole_Ilaj' element={<SareeriyatIlaj/>} />
        <Route path='/Ilaj_Bit_Tadbeer' element={<IlajTadbeer/>} />
        <Route path='/Amraz_e_Atfal' element={<AmrazAtfal/>} />
        <Route path='/Moalajat' element={<Moalajat/>} />
        <Route path='/Amraze_Niswan' element={<AmrazeNiswan/>} />
        <Route path='/Ilmul_Qabalat_wa_Naumaulood' element={<IlmulNaumaulood/>} />
        <Route path='/Ilmul_Jarahat' element={<IlmulJarahat/>} />
        <Route path='/Ain_Uzn_Anaf_Halaq_wa_Asnan' element={<AinUznAnafAsnan/>} />
      </Routes>
    </Suspense>
    </div>
  )
}
