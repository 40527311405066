import React, { useState } from 'react'

export const Card = () => {

    const [card,setCard] = useState({
        title:"",
        dec:""
    })
    // const [des,setDes] = useState('')
    const handleClick = (e)=>{
        setCard({...card,title:card.title,dec:card.dec })
        console.log(card)
        // setCard(e)
    }
    const cardGenerate = (e,card)=>{
        console.log(card)
    }


  return (
    <div>
        <form onSubmit={cardGenerate}>
            <input type='text' placeholder='title' onChange={handleClick}/>
            <input type='text' placeholder='dec' onChange={handleClick}/>
            <input type='submit' placeholder='submit'/>
            </form>
        
        {/* {
            card? "hi":"hello"
        } */}
    </div>
  )
}
