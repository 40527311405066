import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer } from './components/footer/Footer';
// import { Home } from './components/home/Home';
import { MainContainer } from './components/home/MainContainer';
import { Course } from './components/home/course/Course';
import { Navbar } from './components/navbar/Navbar';
import { Syllabus } from './components/home/course/Syllabus';
// import { Reactjs } from './course/b.tech/cse/javascript/Reactjs';
import { AppScreenjs } from './course/b.tech/cse/javascript';
import { AppScreen } from './course/b.tech/cse/java'
import { AppScreenPy } from './course/b.tech/cse/python'
import { Disclaimer, PrivacyPolicy, TermsConditions } from './components/privacypolicy';
import { AppScreenCoding } from './course/b.tech/cse/codingquestion/AppScreenCoding';
// import AdSense from './components/ads/AdSense';
import { AppScreenUnani } from './course/medical/aiapget/unani/AppScreenUnani';
import { UnaniAiapget } from './components/home/entrancetest/aiapget/UnaniAiapget';
import { CodingObjective } from './course/b.tech/cse/codingobjective/CodingObjective';
import { Btech } from './components/home/Btech';
import { MainPortfolio } from './portfolio/MainPortfolio';
import { Physics } from './course/school_syllabus/physics/Physics';
import { PhysicsClass } from './course/school_syllabus/physics/PhysicsClass';
import { Login } from './components/account/Login';
import { Register } from './components/account/Register';
import { Chemistry } from './course/school_syllabus/chemistry/Chemistry';
import { ChemistryClass } from './course/school_syllabus/chemistry/ChemistryClass';
import { Biology } from './course/school_syllabus/biology/Biology';
import { BiologyClass } from './course/school_syllabus/biology/BiologyClass';
import { Math } from './course/school_syllabus/math/Math';
import { MathClass } from './course/school_syllabus/math/MathClass';
import { SubjectName } from './course/bpsc/bpsc_notes/SubjectName';
import { Notes } from './course/bpsc/bpsc_notes/Notes';
import { Questions } from './course/bpsc/bpsc_notes/Questions';
import { Test } from './course/bpsc/bpsc_notes/Test';
import { Card } from './card1/Card';


function App() {
  return (
    <BrowserRouter >
      <Navbar />
      {/* <AdSense/> */}
      <Routes>
        <Route path='/' element={<MainContainer />} />
        <Route path="/course" element={<Course />} />
        <Route path="/syllabus" element={<Syllabus />} />
        <Route path='/cse-language' element={<Btech/>}/>
        <Route path='/javascript/*' element={<AppScreenjs />} />
        <Route path='/java/*' element={<AppScreen />} />
        <Route path='/py/*' element={<AppScreenPy/>}/>
        <Route path='/coding/*' element={<AppScreenCoding/>}/>
        <Route path='/unani'element={<UnaniAiapget/>}/>
        <Route path='/unani/*' element={<AppScreenUnani/>}/>
        <Route path='/objectivecoding' element={<CodingObjective/>}/>
        <Route path='/disclaimer' element={<Disclaimer />} />
        <Route path='/privacyplicy' element={<PrivacyPolicy />} />
        <Route path='/termscondition' element={<TermsConditions />} />
        <Route path='/Abdul-Afjal-Ansari' element={<MainPortfolio/>} />
        <Route path="physics" element={<Physics/>}/>
        <Route path="physics-class" element={<PhysicsClass/>}/>
        <Route path='chemistry' element={<Chemistry/>}/>
        <Route path='chemistry-class' element={<ChemistryClass/>}/>
        <Route path='biology' element={<Biology/>}/>
        <Route path='biology-class' element={<BiologyClass/>}/>
        <Route path='math' element={<Math/>}/>
        <Route path='math-class' element={<MathClass/>}/>
        <Route path='login' element={<Login/>}/>
        <Route path='register' element={<Register/>}/>
        
        <Route path='competitive-syllabus' element={<SubjectName/>}/>
        <Route path='notes' element={<Notes/>}/>
        <Route path='questions' element={<Questions/>}/>
        <Route path='test' element={<Test/>}/>
        <Route path='card' element={<Card/>}/>
      </Routes>
      {/* <MainContainer/> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
