import React from 'react'

export const Review = () => {
  return (
    <div>
      <p class='text-4xl font-serif font-bold mt-10 text-center'>Review</p>
      <div className='w-full flex justify-evenly flex-wrap'>
        <div className='w-full sm:w-1/2 md:w-1/4 lg:w-1/4'>
          <img className='w-full rounded-full' src='https://dt-egrad.myshopify.com/cdn/shop/files/testimonial-3.jpg?v=1690371149&width=550' alt='' />
        </div>
        <div className='w-full sm:w-1/2 md:w-2/3 lg:w-1/2'>
          {/* <p className='text-4xl font-serif p-3'>Learners Tributes</p> */}
          <p className='text-4xl font-serif p-3 ml-3'>Students Review</p>
          <p className='text-xl font-serif p-3 ml-3'>Welcome to our online tutorial platform, where learning begins with foundational knowledge and evolves into professional expertise. Our tutorials are designed to provide you with the technical skills and personal development necessary for success in your chosen field.

            We are dedicated to maintaining high standards by delivering the right content at the right time. In addition to our regular tutorials, we offer seasonal sessions focused on career placements, advanced studies, and exam preparation on SkillVibes.in</p>
        </div>
      </div>
    </div>
  )
}
