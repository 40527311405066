import React from 'react'
import { Link } from 'react-router-dom';
import {Java,Spring,Hibernate,ApacheStruts,JUnit,ApacheMaven,SparkFramework,programming } from '../Image'

export const JavaTechnology = () => {
    const data = [
      {name: "coding questions",img: programming,href: "/coding/codingquestions",},
     {name: "Java",img: Java,href: "/java/java",},
    {name: "Spring",img: Spring,href: "/java/spring",},
    {name: "Hibernate",img: Hibernate, href: "/java/hibernate",},
    { name: "Apache Struts", img: ApacheStruts,  href: "/java/apachestruts",},
    {name: "JUnit",img: JUnit,href: "/java/junit",},
    {name: "Apache Maven",img: ApacheMaven,href: "/java/apachemaven",},
    {name: "Spark Framework",img: SparkFramework, href: "/java/sparkframework",},
    // { name: "C", img: react,  href: "/c",},
    // {name: "C++",img: react,href: "/c++",}
     ];
  return (
    <div>
        <div className='container mx-auto'>
        <p className='text-4xl font-serif font-bold mt-10 text-center'>Java Technology</p>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center mt-10'>
          {data?.map((course, index) => (
            <div key={index} className='bg-custom-gray p-5 rounded-md text-center hover:bg-bg-image group shadow-lg w-full'>
              <Link to={course?.href}> {/* Use Link component and set 'to' prop to href */}
                <img className='mx-auto w-1/2 transition-transform duration-300 transform group-hover:translate-y-4 group-hover:bg-transparent rounded-md' src={course?.img} alt='' />
                <p className='font-bold p-1 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125 truncate'>{course?.name}</p>
                {/* <p className='text-sm p-1 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p> */}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
