import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { MathChapterName, MathNotes } from '../physics/api/Api';
import { MathChapter } from './MathChapter';
export const Math = () => {
  const location = useLocation();
  const classId = location?.state?.id;
  const notesRef = useRef(null)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [chapterIdSelect, setChapterIdSelect] = useState(null);

  const handleSelectedChapterId = (id) => {
    setChapterIdSelect(id);
    if (notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { data, isLoading, isError, error } = useQuery(
    ['data', chapterIdSelect], 
    () => MathNotes(chapterIdSelect), 
    { enabled: !!chapterIdSelect }
  );

  const { data: chapter } = useQuery(['chapter'], () => MathChapterName(classId));

  if (isLoading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  if (isError) return <div className="flex justify-center items-center min-h-screen">Error: {error.message}</div>;

  return (
    <div className="flex flex-wrap mt-6">
      <div>
        <MathChapter 
          chapter={chapter}
          onSelectedChapterId={handleSelectedChapterId}
        />
      </div>
      <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
          <p className="breadcrumb text-gray-600 mb-4">
            Home » <span className="breadcrumb-link text-blue-600"> {location?.state?.class_name} <span>» </span>{data?.chapter_name}</span> » 
          </p>
          <h2 className="react-question text-4xl font-semibold mb-2">{data?.chapter_name}</h2>
          <div className="qa-container mb-6">
            {data?.note?.map((alldata, index) => (
              <div key={index} className="react-answer text-gray-700 mb-4">
                <span dangerouslySetInnerHTML={{ __html: alldata.note }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
