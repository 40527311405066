import React, { useState } from 'react';
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";

export const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const data = [
        { name: "Home", href: '/' },
        { name: "Course", href: '/' },
        { name: "B.Tech", href: '/cse-language' },
        { name:"Physics",href:'/physics-class'},
        { name:"Chemistry",href:'/chemistry-class'},
        { name:"Biology",href:'/biology-class'},
        { name:"Math",href:'/math-class'},
        { name: "Contest", href: '/objectivecoding' },
        { name: "Page", href: '/' },
        { name: "About Me", href: '/Abdul-Afjal-Ansari' }
    ];

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className='w-full h-[70px] flex justify-between items-center md:justify-around' style={{ background: '#f0f0f0' }}>
            <div className="flex items-center">
                <p className="text-2xl font-serif font-bold">SkillVibes</p>
            </div>
            <div className='hidden md:flex justify-center'>
                {
                    data.map((item, index) => (
                        <ul className='flex' key={index}>
                            <Link to={item.href}><li className='font-xl p-4 font-serif font-bold '>{item.name}</li></Link>
                        </ul>
                    ))
                }
            </div>
            <div className='flex justify-center items-center'>
                <p className='font-xl p-4 font-serif font-bold'><CgProfile size={30} /></p>
                <a href='login'> <p className='font-xl p-4 font-serif font-bold' >Sign Up</p></a>
                <div className='md:hidden' onClick={toggleSidebar}>
                    {sidebarOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
                </div>
            </div>
            {sidebarOpen && (
                <div className='fixed inset-0 bg-gray-800 bg-opacity-50 z-50'>
                    <div className='fixed top-0 left-0 w-64 h-full bg-white shadow-lg z-50'>
                        <div className="flex items-center justify-between p-4">
                            <p className="text-2xl font-serif font-bold">SkillVibes</p>
                            <FaTimes size={30} onClick={toggleSidebar} />
                        </div>
                        <div className='flex flex-col'>
                            {
                                data.map((item, index) => (
                                    <Link to={item.href} key={index} onClick={toggleSidebar}>
                                        <li className='font-xl p-4 font-serif font-bold border-b'>{item.name}</li>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
