import React from 'react'
import { Home } from '../Home'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router'
import { SyllabusData } from '../../../api/Api'

export const Syllabus = () => {
    const location = useLocation()
    console.log(location,"id9")
    const id = location.state.id
    const {data} = useQuery(['data'],()=>SyllabusData(id))
    console.log(data,"data")
  
    const navigate = useNavigate()
  
    const handleNavigate = (id)=>{
      console.log(id,"id")
      navigate('/course')
    }
  return (
    <div>
        <Home/>
        <div class='container mx-auto'>
        <p class='text-4xl font-serif font-bold mt-10 text-center'>Learn Best Things</p>
        <div class='flex flex-wrap justify-evenly items-center box-content'>
          {
            data?.map((course)=>(
              <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group' onClick={()=>handleNavigate(course.id)}>
                {/* {console.log(course)} */}
              {/* <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' /> */}
              <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>{course?.syllabus} {course?.course}</p>
              <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
            </div>
            ))
           } 
          {/* <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div>
          <div class='bg-custom-yellow p-5 rounded-md text-center w-[300px] m-2 hover:bg-bg-image group'>
            <img class='mx-auto p-3 transition-transform duration-300 transform group-hover:translate-y-4 bg-slate-500 group-hover:bg-transparent rounded-md' src={subject} alt='' />
            <p class='font-bold p-2 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125'>Arts & Craft</p>
            <p class='text-sm p-4 opacity-100 group-hover:opacity-0 transition-opacity duration-300'>All subjects</p>
          </div> */}
        </div>
      </div>
    </div>
  )
}
