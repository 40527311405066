import React from 'react';
import { test } from '../../../components/home/Image';
import { useQuery } from 'react-query';
import { PhysicsAllClass } from '../api/Api';
import { useNavigate } from 'react-router-dom';


export const SubjectName = () => {
  const { data } = useQuery('physicsclass', PhysicsAllClass);
  const navigate = useNavigate();
console.log(data)
  const handleNavigate = (id,subject_name) => {
    navigate('/notes', { state: { id,subject_name } });
  };
  const handleNavigateQuestions = (id,subject_name) => {
    navigate('/questions', { state: { id,subject_name } });
  };
  const handleNavigateTest = (id,subject_name) => {
    navigate('/test', { state: { id,subject_name } });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">General Competition Subject and Notes</h1>
      <div className="flex flex-wrap -mx-4">
        {data?.data?.map((item) => (
          <div key={item.id} className="w-full md:w-1/2 xl:w-1/3 p-4">
            <div className="bg-white rounded shadow-md p-4 cursor-pointer">
              <img src={test} alt={`Class_${item.class_name}_Image`} className="w-full h-64 object-cover mb-4" />
              <p className="text-2xl font-bold mb-2">{item.subject_name}</p>
              <div className='flex p-3'>
                <p className="text-lg font-bold mb-2 p-2 cursor-pointer bg-blue-800 ms-2 text-white rounded-md" onClick={() => handleNavigate(item.id,item?.subject_name)}>Notes</p>
                <p className="text-lg font-bold mb-2 p-2 cursor-pointer bg-blue-800 ms-2 text-white rounded-md"  onClick={() => handleNavigateQuestions(item.id,item?.subject_name)}>Questions</p>
                <p className="text-lg font-bold mb-2 p-2 cursor-pointer bg-blue-800 ms-2 text-white rounded-md" onClick={() => handleNavigateTest(item.id,item?.subject_name)}>Test</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};