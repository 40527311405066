import React, { useEffect } from 'react';
import abdul from '../components/assets/abdulnew.jpg';
import * as d3 from 'd3';
// import PolygonBackground from './PolygonBackground ';
// import DotBackground from './DotBackground';
// import PolygonBackground from './PolygonBackground';




// const ProjectCard = ({ title, description }) => {
//     useEffect(() => {
//         const card = d3.selectAll('.project-card');
//         card
//             .on('mouseover', function () {
//                 d3.select(this)
//                     .transition()
//                     .duration(300)
//                     .style('transform', 'scale(1.05)')
//                     .style('box-shadow', '0 4px 8px rgba(0,0,0,0.2)');
//             })
//             .on('mouseout', function () {
//                 d3.select(this)
//                     .transition()
//                     .duration(300)
//                     .style('transform', 'scale(1)')
//                     .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)');
//             });
//     }, []);

//     return (
//         <div className="project-card bg-white shadow-md rounded-lg p-6 max-w-xs text-center">
//             <h3 className="text-2xl font-bold">{title}</h3>
//             <p>{description}</p>
//         </div>
//     );
// };

const SkillCard = ({ skill }) => {
    useEffect(() => {
        const card = d3.selectAll('.skill-card');
        card
            .on('mouseover', function () {
                d3.select(this)
                    .transition()
                    .duration(300)
                    .style('transform', 'scale(1.1)')
                    .style('box-shadow', '0 6px 12px rgba(0,0,0,0.2)');
            })
            .on('mouseout', function () {
                d3.select(this)
                    .transition()
                    .duration(300)
                    .style('transform', 'scale(1)')
                    .style('box-shadow', '0 4px 8px rgba(0,0,0,0.1)');
            });
    }, []);

    return (
        <div className="skill-card bg-white shadow-md rounded-lg p-6 max-w-xs text-center">
            <h3 className="text-xl font-bold">{skill}</h3>
        </div>
    );
};


export const MainPortfolio = () => {

    useEffect(() => {
        // Add hover effects to all <p> elements
        d3.selectAll('p')
            .on('mouseover', function () {
                d3.select(this)
                    .transition()
                    .duration(300)
                    .style('color', 'blue')
                    .style('font-size', '24px');
            })
            .on('mouseout', function () {
                d3.select(this)
                    .transition()
                    .duration(300)
                    .style('color', 'black')
                    .style('font-size', '16px');
            });
    }, []);


    const skills = [
        'JavaScript (ES6+)', 'HTML5', 'CSS3', 'MongoDB', 'Python(Django)', 'Node.js', 'Express js', 'React js', 'React Native', 'Nextjs', 'Redux', 'Redux Toolkit', 'Context API', 'Bootstrap', 'jQuery', 'Tailwindcss', 'Sass', 'axios', 'API Integration', 'MaterialUI', 'Git', 'GitHub'
    ];

    return (
        <>
            {/* <DotBackground /> */}
            <div className="flex flex-col items-center w-full">

                <div className="relative w-full h-[70vh] bg-cover bg-center" style={{ backgroundImage: `url(${abdul})` }}>
                    <div className="absolute inset-0 flex items-center justify-center">
                        {/* <div className="container  bg-opacity-80 p-6 md:p-12 flex flex-col md:flex-row items-center md:justify-between rounded-lg shadow-lg"> */}
                            {/* <div className="md:w-1/2 text-center md:text-left p-6 md:p-8">
                                <h3 className="text-3xl md:text-4xl lg:text-4xl font-bold text-gray-900 mb-2">Hello, I'm</h3>
                                <h3 className="text-3xl md:text-4xl lg:text-4xl font-bold text-gray-800 mb-2">Abdul Afjal Ansari</h3>
                                <h3 className="text-xl md:text-2xl lg:text-3xl text-gray-700 mb-4">Web Developer from India</h3>
                                <p className="text-lg text-gray-600">
                                    If I ever make a mistake, please don't blame me for it. I strive to do my best.
                                </p>
                            </div>
                            <div className="md:w-1/2 p-6 flex justify-center">
                                <img src={abdul} alt="Abdul" className="rounded-full shadow-lg border-4 border-gray-200 max-w-full h-auto" />
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>


                <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8 p-4 mb-10">
                    <div className="bg-white shadow-md rounded-lg p-4 max-w-sm w-full">
                        <div className="flex items-center justify-between space-x-4">
                            <div>
                                <p className="text-2xl font-bold">01</p>
                                <p className="text-xl">Dedication</p>
                            </div>
                            <div>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPHZYCmMziWugspdQ7O8r0k6IryuPHGDy5Dw&s" alt="Dedication Icon" className="w-12 h-12" />
                            </div>
                        </div>
                        <div className="mt-4 p-5">
                            <p>Dedication is the fuel that drives you forward, pushing you beyond your limits and transforming your goals into reality. It’s the unwavering commitment to your dreams, no matter the obstacles.</p>
                        </div>
                    </div>

                    <div className="bg-white shadow-md rounded-lg p-4 max-w-sm w-full">
                        <div className="flex items-center justify-between space-x-4">
                            <div>
                                <p className="text-2xl font-bold">02</p>
                                <p className="text-xl">Smart Work</p>
                            </div>
                            <div>
                                <img src="https://png.pngtree.com/png-vector/20230217/ourmid/pngtree-chip-ai-human-brain-intelligence-technology-chip-high-tech-circuit-board-png-image_6606248.png" alt="Smart Work Icon" className="w-12 h-12" />
                            </div>
                        </div>
                        <div className="mt-4 p-5">
                            <p>Smart work involves maximizing efficiency and effectiveness through strategic planning and resourceful problem-solving. It’s about working smarter, not just harder, to achieve your goals with less effort and more impact.</p>
                        </div>
                    </div>

                    <div className="bg-white shadow-md rounded-lg p-4 max-w-sm w-full">
                        <div className="flex items-center justify-between space-x-4">
                            <div>
                                <p className="text-2xl font-bold">03</p>
                                <p className="text-xl">Intelligence</p>
                            </div>
                            <div>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOMcyz8F7GDaiG8GMydwlbNFATogy510IWmA&s" alt="Intelligence Icon" className="w-12 h-12" />
                            </div>
                        </div>
                        <div className="mt-4 p-5">
                            <p>Intelligence is not just about knowledge but also about the ability to apply it effectively. It’s about understanding complexities, making informed decisions, and leveraging your cognitive abilities to navigate challenges and opportunities.</p>
                        </div>
                    </div>
                </div>
                {/* <DotBackground /> */}
                <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8 p-4 mb-10">
                    <div className="flex flex-wrap md:flex-row items-center gap-7 justify-center">
                        <div className="w-full md:w-1/2 flex justify-center">
                            <img src={abdul} alt="Abdul" className="rounded-2xl shadow-lg w-10/12 h-auto" />
                        </div>
                        <div className="w-full md:w-1/3 text-center md:text-left">
                            <p className="text-xl text-red-500 font-serif">I'm a Developer</p>
                            <p className="text-4xl font-bold font-sans">I Develop Applications that Help People</p>
                            <p className="font-serif mt-4">
                                "Diligently take out from it the number one quality. Use the pain in pleasure to ensure that there is no pain from the pleasure, except it is something that attracts the heart. However, they are to be blamed for the ones who avoid taking on the responsibilities, as they are at fault for being lazy. But to achieve perfection, everyone must..."
                            </p>
                            <button className="bg-blue-900 text-white py-3 px-6 text-xl rounded-2xl hover:bg-blue-600 mt-4">
                                <a href="/">View Portfolio</a>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <DotBackground /> */}
                <div className="experience-section p-4">
                    <h2 className="text-3xl font-bold mb-6 text-center">Professional Experience (2+)</h2>
                    <div className="flex flex-col space-y-6">

                        {/* <!-- Software Developer at Amosta Solution Pvt. Ltd. --> */}
                        <div className="bg-white  shadow-md rounded-lg p-6">
                            <h3 className="text-2xl font-semibold">Software Developer</h3>
                            <p className="text-xl text-gray-600">Amosta Solution Pvt. Ltd. | [August 2022]</p>
                            <ul className="list-disc-with-arrow ml-6 mt-2 text-gray-700">
                                <li>Developed web and mobile applications using a diverse technology stack including JavaScript (ES6+), HTML5, CSS3, Python (Django), Node.js, Express, and MongoDB.</li>
                                <li>Utilized React.js, React Native, and Next.js for building dynamic and responsive user interfaces.</li>
                                <li>Managed state and side effects with Redux, Redux Toolkit, and Context API.</li>
                                <li>Styled applications using Bootstrap, Tailwind CSS, Sass, and MaterialUI.</li>
                                <li>Integrated APIs using Axios,React-Query and ensured smooth data flow between frontend and backend systems.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Skills Section */}
                <div className="w-full p-4 mb-10">
                    <h2 className="text-3xl font-bold text-center mb-6">Skills</h2>
                    <div className="grid grid-cols-2 text-xl sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-3 justify-items-center">
                        {skills.map((skill, index) => (
                            <SkillCard key={index + 1} skill={skill} />
                        ))}
                    </div>
                </div>

                {/* <DotBackground /> */}
                {/* Projects Section */}
                <div className="flex flex-col items-center w-full bg-gray-50 py-10">
                    {/* Projects Section */}
                    <div className="w-full max-w-7xl p-4 mb-10">
                        <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-800 tracking-wide">Projects</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                            <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-4 text-gray-700">amosta.com</h3>
                                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                                        <li>Involved in requirements identification, design, and implementation of the feature end to end.</li>
                                        <li>Individual contributor to design documentation.</li>
                                        <li>Responsible for overall code quality of the user stories and deliverables.</li>
                                        <li>Technologies used: React JS, Material UI, JavaScript.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-4 text-gray-700">humhai.in</h3>
                                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                                        <li>Created humhai.in UI design and functions using HTML, CSS, JavaScript, Bootstrap, TailwindCSS.</li>
                                        <li>Designed and created a background verification system in humhai.in.</li>
                                        <li>Designed and created an HR management system in humhai.in.</li>
                                        <li>Created a job portal using Python Django, JavaScript, HTML, CSS, Bootstrap, TailwindCSS.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-4 text-gray-700">adornmentsbydeepti.com</h3>
                                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                                        <li>Developed a feature-rich e-commerce platform using React, providing users with a dynamic and intuitive shopping experience.</li>
                                        <li>Implemented product catalog pages, user authentication, and order processing functionalities.</li>
                                        <li>Utilized React Router for seamless navigation and an improved user experience.</li>
                                        <li>Developed and maintained an e-commerce platform with a focus on secure and user-friendly payment processes.</li>
                                        <li>Implemented and optimized payment workflows to enhance the customer checkout experience.</li>
                                        <li>Designed and implemented responsive layouts for optimal performance on various devices.</li>
                                        <li>Technologies Used: React, React Router, TailwindCSS, backend: Python Django.</li>
                                        <li>Live Demo: <a href="https://adornmentsbydeepti.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 underline">https://adornmentsbydeepti.com/</a></li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
                <div className="p-6">
                    <h3 className="text-xl font-semibold mb-4 text-gray-700">HUMHAI MOBILE APP</h3>
                    <ul className="list-disc list-inside text-gray-600 space-y-2">
                        <li>"HumHai" is a mobile app with two distinct modules: Candidate Management and Events Ticket Management.</li>
                        <li><strong>Candidate Management Module:</strong></li>
                        <ul className="list-disc list-inside ml-6 text-gray-600 space-y-2">
                            <li>Candidate Profiles: Create and store detailed profiles for each candidate, including contact information, resume, and other relevant details.</li>
                            <li>Application Tracking: Monitor the progress of each candidate in the hiring pipeline, from application submission to the final decision.</li>
                        </ul>
                        <li><strong>Events Ticket Management Module:</strong></li>
                        <ul className="list-disc list-inside ml-6 text-gray-600 space-y-2">
                            <li>Event Creation: Enable organizers to create and set up events, providing details such as date, time, venue, and any additional information.</li>
                            <li>Ticket Generation: Generate and manage tickets for attendees, possibly including QR codes or barcodes for easy check-in.</li>
                        </ul>
                        <li>Technologies Used: React Native, TailwindCSS, backend: Python Django [Deployment on Play Store].</li>
                    </ul>
                </div>
            </div> */}
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};
