import axios from 'axios'


const baseURL = 'https://skillvibes.in'


export const PhysicsAllClass = async()=>{
    try {
        const physicsAllClass = axios.get(`${baseURL}/exam`);
        return physicsAllClass
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}


export const PhysicsChapterName = async (classId) =>{
    try {
        const chapter  = await axios.get(`${baseURL}/exam/topic/${classId}`);
        return chapter.data;
    } catch (error) {
        throw new Error("somthing went wrong")
    }
}

export const PhysicsNptes = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/exam/notes/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}
export const ObjectiveQuestions = async (chapterId) => {
    try {
        const response = await axios.get(`${baseURL}/exam/objective/${chapterId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}




export const TestList = async (classId, page = 1) => {
    try {
        const response = await axios.get(`${baseURL}/exam/test/${classId}?page=${page}`);
        return response.data;
    } catch (error) {
        throw new Error("Something went wrong");
    }
}