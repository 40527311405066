import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { CodingData, LanguageListData, SolutionData } from './Api';

export const CodingQuestion = () => {
  const [questionId, setQuestionId] = useState(514);
  const [languageId, setLanguageId] = useState(1);
  const [questionGet, setQuestionsGet] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, questionGet]);

  const { data: codingData } = useQuery(['codingData'], CodingData);
  const { data: languageData } = useQuery(['languageData'], LanguageListData);
  const { data: solutionData } = useQuery(
    ['solutionData', questionId, languageId],
    () => SolutionData(languageId, questionId),
    {
      enabled: !!questionId && !!languageId, // Fetch only when both IDs are available
    }
  );

  const handleClickId = (id) => {
    setQuestionId(id);
    console.log(id, "selected id");
  };

  const handleClickQue = (que) => {
    setQuestionsGet(que);
    console.log(que, "selected id");
  };

  const handleLanguageId = (id) => {
    setLanguageId(id);
    console.log(id, "language id");
  };

  return (
    <div className="relative flex flex-col lg:flex-row">
      <button
        className="lg:hidden bg-custom-yellow text-black p-2 m-2 rounded"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        {sidebarOpen ? 'Close Sidebar' : 'Interview Questions'}
      </button>

      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      <div
        className={`fixed lg:relative top-0 left-0 w-64 lg:w-1/4 bg-custom-yellow h-full p-4 z-20 transition-transform duration-300 transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full '
        } lg:translate-x-0 overflow-y-auto`}
      >
        <p className="bg-black text-white text-2xl text-center mb-4">Interview Questions</p>
        {codingData?.map((coding, index) => (
          <p
            key={index}
            className="text-lg mb-2 cursor-pointer hover:bg-slate-300"
            onClick={() => {
              handleClickId(coding.id);
              handleClickQue(coding.question);
              setSidebarOpen(false); // Close sidebar on mobile when a question is selected
            }}
          >
            {index + 1}. {coding.question}
          </p>
        ))}
      </div>

      <div className="w-full lg:w-3/4 bg-custom-gray h-auto p-4 lg:ml-3">
        <div className="bg-gray-100 min-h-screen p-4 rounded shadow">
          <p className="bg-black text-white text-2xl text-center p-3 rounded mb-4">Interview Questions</p>
          <p className="text-lg mb-2">{questionGet}</p>
          <ul className="flex flex-wrap mb-4">
            {languageData?.map((lang, index) => (
              <li
                key={index}
                className={`p-3 bg-red-500 text-black shadow rounded mx-2 mb-2 cursor-pointer ${
                  languageId === lang.id ? 'bg-black text-white' : ''
                }`}
                onClick={() => handleLanguageId(lang.id)}
              >
                {lang?.language_name}
              </li>
            ))}
          </ul>
          <div className="code-container bg-white p-4 rounded shadow mb-4">
            <div className="flex justify-end">
              <button className="bg-black text-white px-4 py-2 rounded shadow focus:outline-none">
                Copy Code
              </button>
            </div>
            <pre>
              <code className="whitespace-pre-wrap">
                {solutionData && solutionData.length > 0 ? solutionData[0].answer : 'No solution available'}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
