import React from 'react'
import logo from '../assets/support-icon1.webp'
import logo1 from '../assets/support-icon2.webp'

export const TimeMnagement = () => {
  return (
    <div className='flex flex-wrap justify-center md:justify-evenly items-center p-4'>
      <div className='bg-custom-yellow p-5 rounded-md text-center w-full sm:w-1/2 lg:w-1/4 m-2'>
        <img className='mx-auto p-3' src={logo} alt='One-On-One Teaching'/>
        <p className='font-bold p-2'>One-On-One Teaching</p>
        <p className='text-sm p-4'>One-on-one teaching offers personalized instruction, tailored lessons, and individual feedback, enhancing engagement and effectiveness to maximize student potential in various educational settings.</p>
      </div>
      <div className='bg-custom-pink p-5 rounded-md text-center w-full sm:w-1/2 lg:w-1/4 m-2'>
        <img className='mx-auto p-3' src={logo1} alt='24/7 Tutor Availability'/>
        <p className='font-bold p-2'>24/7 Tutor Availability</p>
        <p className='text-sm p-4'>Get round-the-clock access to personalized academic support with our 24/7 tutor availability, ensuring help whenever you need it for enhanced learning success.</p>
      </div>
      <div className='bg-custom-gray p-5 rounded-md text-center w-full sm:w-1/2 lg:w-1/4 m-2'>
        <img className='mx-auto p-3' src={logo} alt='Interactive Whiteboard'/>
        <p className='font-bold p-2'>Interactive Whiteboard</p>
        <p className='text-sm p-4'>An interactive whiteboard is a digital display that allows users to write, draw, and interact with content, enhancing collaboration and engagement in educational and professional settings.</p>
      </div>
    </div>
  );
}
