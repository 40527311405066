import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import {Python, Celery, Django, Flask, Metaplotlib, Numpy, Pandas, Request, Tensorflowandpytorch} from './index'

export const AppScreenPy = () => {
    return (
        <div>
            <Suspense>
                <Routes>
                    <Route path='/python' element={<Python />} />
                    <Route path='/celery' element={<Celery />} />
                    <Route path='/django' element={<Django />} />
                    <Route path='/flask' element={<Flask />} />
                    <Route path='/metaplotlib' element={<Metaplotlib />} />
                    <Route path='/numpy' element={<Numpy />} />
                    <Route path='/pandas' element={<Pandas />} />
                    <Route path='/request' element={<Request />} />
                    <Route path='/tensorflowpytorch' element={<Tensorflowandpytorch />} />
                   
                </Routes>
            </Suspense>
        </div>
    )
}
