import axios from "axios"

const baseURL = 'https://skillvibes.in'


export const LanguageListData = async (page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(`${baseURL}/codings/language/`, {
            params: { page, page_size: pageSize }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}
export const CodingData = async (page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(`${baseURL}/codings/coding/`, {
            params: { page, page_size: pageSize }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}
export const SolutionData = async (languageId, questionId=514) => {
    try {
        // console.log(questionId, languageId, "ids");
        const response = await axios.get(`${baseURL}/codings/codingsolution/${questionId}/`, {
            params: {
                language: languageId
            }
        });
        // console.log(response,"respo")
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}

// /codings/codingsolution/514/?language=7