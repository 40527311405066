import React from 'react';
import { Link } from 'react-router-dom';
import { Javascript, Angular, Chart, Css, D3js, Express, Html, Jquery, Moment, Nest, Next, Node, Reactjs, Redux, Three, Typescript, Vue, programming } from '../Image';

export const WebTechnology = () => {
    const data = [
        { name: "Objective Test", img: programming, href: "/objectivecoding" },
        { name: "coding questions", img: programming, href: "/coding/codingquestions" },
        { name: "JavaScript", img: Javascript, href: "/javascript/javascript" },
        { name: "React", img: Reactjs, href: "/javascript/react" },
        { name: "Angular", img: Angular, href: "/javascript/angular" },
        { name: "Typescript", img: Typescript, href: "/javascript/typescript" },
        { name: "Redux", img: Redux, href: "/javascript/redux" },
        { name: "Vue js", img: Vue, href: "/javascript/vue" },
        { name: "JQuery", img: Jquery, href: "/javascript/jquery" },
        { name: "Node js", img: Node, href: "/javascript/nodejs" },
        { name: "Express js", img: Express, href: "/javascript/expressjs" },
        { name: "Next js", img: Next, href: "/javascript/nextjs" },
        { name: "Nest js", img: Nest, href: "/javascript/nestjs" },
        { name: "Moment js", img: Moment, href: "/javascript/momentjs" },
        { name: "D3js", img: D3js, href: "/javascript/d3js" },
        { name: "Three js", img: Three, href: "/javascript/threejs" },
        { name: "Chart js", img: Chart, href: "/javascript/chartjs" },
        { name: "HTML", img: Html, href: "/javascript/html" },
        { name: "CSS", img: Css, href: "/javascript/css" }
    ];

    return (
        <div className='container mx-auto px-4'>
            <p className='text-4xl font-serif font-bold mt-10 text-center'>Web Technology</p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center mt-10'>
                {data?.map((course, index) => (
                    <div key={index} className='bg-custom-gray p-5 rounded-md text-center hover:bg-bg-image group shadow-lg w-full'>
                        <Link to={course?.href}>
                            <img className='mx-auto w-1/2 transition-transform duration-300 transform group-hover:translate-y-4 group-hover:bg-transparent rounded-md' src={course?.img} alt='' />
                            <p className='font-bold p-1 group-hover:text-white transition-transform duration-300 transform group-hover:translate-y-4 group-hover:scale-125 truncate'>{course?.name}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};
