import React from 'react'
import { TopicName } from './TopicName';
import { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { ObjectiveQuestions, PhysicsChapterName } from '../api/Api';
import { useLocation } from 'react-router-dom';
export const Questions = () => {
    const location = useLocation();
    const classId = location?.state?.id;
    const notesRef = useRef(null);
  console.log(location,"location")
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
  
    const [chapterIdSelect, setChapterIdSelect] = useState(null);
    const [chapterTopic, setChapterTopicSelect] = useState(null);
  
    const handleSelectedChapterId = (id,topicName) => {
        console.log(id,"id")
      setChapterIdSelect(id);
      setChapterTopicSelect(topicName)
      if (notesRef.current) {
        notesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    const { data, isLoading, isError, error } = useQuery(
      ['data', chapterIdSelect], 
      () => ObjectiveQuestions(chapterIdSelect), 
      { enabled: !!chapterIdSelect }
    );
  console.log(data,"dataa")
    const { data: topic } = useQuery(['chapter'], () => PhysicsChapterName(classId));
  console.log(topic,"topic")
    if (isLoading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    if (isError) return <div className="flex justify-center items-center min-h-screen">Error: {error.message}</div>;
  
    return (
      <div className="flex flex-wrap mt-6">
        <div>
          <TopicName 
            topic={topic}
            onSelectedChapterId={handleSelectedChapterId}
            subjectName={location?.state.subject_name}
          />
        </div>
        <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
          <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
            <p className="breadcrumb text-gray-600 mb-4">
              Home » <span className="breadcrumb-link text-blue-600"> {location?.state?.subject_name} <span>» </span>{chapterTopic}</span> » 
            </p>
            <h2 className="react-question text-4xl font-semibold mb-2">{data?.chapter_name}</h2>
            <div ref={notesRef} className="qa-container mb-6">
              {data?.map((alldata, index) => (
                <div key={index} className="react-answer text-gray-700 mb-4">
                  <span dangerouslySetInnerHTML={{ __html: alldata.objective_notes }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
