import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const AinUznAnafAsnan = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="main-container">
      <div className="container">
        <p className="breadcrumb">
          Home » <span className="breadcrumb-link">Programming</span> » <span className="breadcrumb-current">What is the Arabic word for 'book'?</span>
        </p>
        <div key="alldata.id" className="qa-container">
          <p className="react-question">What is the Arabic word for 'book'?</p>
          <div className={`w-full flex flex-wrap justify-between ${isMobile ? 'md:flex-col md:items-center' : ''}`}>
            <p className="answer-option">(A) كتاب</p>
            <p className="answer-option"> (B) قلم</p>
            <p className="answer-option">(C) مدرسة</p>
            <p className="answer-option">(D) حاسوب</p>
          </div>
          <div className="answer-container">
            <p className="react-answer">Answer: (A) كتاب</p>
          </div>
        </div>
        <div key="alldata.id" className="qa-container">
          <p className="react-question">Mantiq wa Falsifa Objective Questions
What is the primary aim of Mantiq?</p>
          <div className={`w-full flex flex-wrap justify-between ${isMobile ? 'md:flex-col md:items-center' : ''}`}>
            <p className="answer-option">(A) To study metaphysics</p>
            <p className="answer-option"> (B) To understand the natural world</p>
            <p className="answer-option">(C) To develop sound reasoning</p>
            <p className="answer-option">(D) To interpret religious texts</p>
          </div>
          <div className="answer-container">
            <p className="react-answer">Answer: (C) To develop sound reasoning</p>
          </div>
        </div>

        {/* Removed pagination for simplicity */}
      </div>
    </div>
  );
};

