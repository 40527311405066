import React from 'react'

export const TopicName = ({ topic, onSelectedChapterId ,subjectName}) => {

    const handleClick = (id,topicName) => {
        onSelectedChapterId(id,topicName);
      };
    
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
          <div className="max-w-3xl w-full bg-white p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold mb-4 text-center">{subjectName}</h1>
            {topic?.map((chapterData) => (
              <div key={chapterData.id} className="chapter-list mb-4">
                <h2
                  className="text-xl font-semibold mb-2 cursor-pointer"
                  onClick={() => handleClick(chapterData.id,chapterData.topics)}
                >
                  {chapterData.topics}
                </h2>
              </div>
            ))}
          </div>
        </div>
      );
    };
