import React, { useEffect, useState } from 'react';
import { ObjectiveQue } from './ObjectiveQue';
import { useQuery } from 'react-query';
import { ObjectiveList } from './api';

export const CodingObjective = () => {
    const [page, setPage] = useState(1);
    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);
    
    const { data, isLoading, isError, error } = useQuery(['data', page], () => ObjectiveList(page), { keepPreviousData: true });

    useEffect(() => {
        const correctData = JSON.parse(localStorage.getItem('correctCount')) || 0;
        const incorrectData = JSON.parse(localStorage.getItem('incorrectCount')) || 0;
        setCorrect(correctData);
        setIncorrect(incorrectData);
    }, []);

    const handleUpdateCounts = (isCorrect) => {
        if (isCorrect) {
            const newCorrectCount = correct + 1;
            setCorrect(newCorrectCount);
            localStorage.setItem('correctCount', JSON.stringify(newCorrectCount));
        } else {
            const newIncorrectCount = incorrect + 1;
            setIncorrect(newIncorrectCount);
            localStorage.setItem('incorrectCount', JSON.stringify(newIncorrectCount));
        }
    };

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className='min-h-screen flex flex-col'>
            <div className='fixed top-0 left-0 right-0 flex flex-col sm:flex-row justify-evenly items-center p-3 bg-white shadow-md z-10'>
                <p className='text-lg sm:text-2xl md:text-3xl text-green-600 font-bold font-custom-font'>Correct - {correct}</p>
                <p className='text-lg sm:text-2xl md:text-3xl text-red-600 font-bold font-custom-font'>Incorrect - {incorrect}</p>
                <p className='text-lg sm:text-2xl md:text-3xl text-black font-bold font-custom-font'>Remaining: {data ? data.total - correct - incorrect : 0}</p>
            </div>

            <div className='flex-grow flex justify-center items-center bg-gray-100 pt-20'>
                <ObjectiveQue data={data} onUpdateCounts={handleUpdateCounts} />
            </div>

            <div className="pagination flex justify-center space-x-4 mt-4 pb-4">
                <button
                    type="button"
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                    className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    Previous
                </button>
                <span className='text-lg'>{`Page ${page} of ${data?.total_pages}`}</span>
                <button
                    type="button"
                    onClick={handleNextPage}
                    disabled={page === data?.total_pages}
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${page === data?.total_pages ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    Next
                </button>
            </div>
        </div>
    );
};
