import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Java,ApacheMaven,ApacheStruts,Hibernate,JUnit,SparkFramework,Spring } from './index'

export const AppScreen = () => {
    return (
        <div>
            <Suspense>
                <Routes>
                    <Route path='/java' element={<Java />} />
                    <Route path='/apachemaven' element={<ApacheMaven />} />
                    <Route path='/apachestruts' element={<ApacheStruts />} />
                    <Route path='/hibernate' element={<Hibernate />} />
                    <Route path='/junit' element={<JUnit />} />
                    <Route path='/sparkframework' element={<SparkFramework />} />
                    <Route path='/spring' element={<Spring />} />
                </Routes>
            </Suspense>
        </div>
    )
}
